import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  Typography,
  Container,
  Paper,
  Box,
  Grid,
  MenuItem,
  Snackbar,
  Alert,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useEditing } from "../../../../context/EditingProvider";
import { faL } from "@fortawesome/free-solid-svg-icons";
import SuchiModal from "./SuchiModal";


const SuchiTable = ({branchName, branchId}) => {
  const [tableData, setTableData] = useState([]);
  const baseUrl = useSelector((state) => state.baseUrl).backend;
  const { isEditing, setIsEditing } = useEditing();
  const [isOpen, setIsOpen] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [suchiData, setSuchiData] = useState(null);  
  
  useEffect(()=>{
    const fetchSuchiData = async ()=>{
      try{
        const response =await axios.get(`${baseUrl}/api/suchi/?branch=${branchId}`);
        if (response.status === 200){
          setTableData(response.data);
        };
        console.log(response.data)

      }catch(error){
        console.log(error);
      };
    };
    fetchSuchiData();
  },
  [baseUrl]
  );


  const delSuchi = async (suchiId) =>{
    const isConfirmed = window.confirm("Are you sure you want to delete this item?");
    if (!isConfirmed) return;
    try{
      const response = await axios.delete(`${baseUrl}/api/suchi/${suchiId}`)
      if (response.status===204){
        // show success
        setTableData(prev => prev.filter(item => item.id !== suchiId))
      }
    }catch(error){
      console.log(error);
      //display error
    }
  };

  return(
    <Container>
      <Paper elevation={3} sx={{padding:3}}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        position: 'relative', // Needed for absolute positioning
        mb: 2 
      }}>
        {/* Centered Title */}
        <Typography 
          variant="h6" 
          sx={{ 
            position: 'absolute', 
            left: '50%', 
            transform: 'translateX(-50%)',
            width: 'fit-content'
          }}
        >
          Suchi List
        </Typography>
        
        {/* Right-aligned Button */}
        <Button 
          variant="contained" 
          color="primary"
          onClick={() => {
            setIsCreating(true);
            setIsOpen(true);
          }}
          sx={{ ml: 'auto' }} // Pushes button to the right
        >
          Add New Suchi
        </Button>
      </Box>

        <Box sx={{ overflowX: "auto" }}>
          <table className = "w-full text-center border-collapse">
            <thead>
              <tr className="text-md font-semibold tracking-wide text-white bg-blue-700">
              <th className="p-2 border-gray-200 border-e-2">Company Name (NP)</th>
                <th className="p-2 border-gray-200 border-e-2">Company Name (EN)</th>
                <th className="p-2 border-gray-200 border-e-2">PAN Number</th>
                <th className="p-2 border-gray-200 border-e-2">Tax Clearance</th>
                <th className="p-2 border-gray-200 border-e-2">Company Registration Date</th>
                <th className="p-2 border-gray-200 border-e-2">Organization Registration Date</th>
                <th className="p-2 border-gray-200 border-e-2">Branch</th>
                {isEditing ? <th className="p-2 border-gray-200 border-e-2">Actions</th>:null}
              </tr>
            </thead>

            <tbody>
              {tableData.map(
                (row)=>(
                  <tr key={row.id}
                    className="transition-colors duration-300 bg-white border-b-2 border-gray-300 hover:bg-blue-50 last:border-b-0"
                  >
                    <td className="p-4 text-gray-700 border-gray-200 border-e-2">
                      {row.company_name.Nepali}
                    </td>
                    <td className="p-4 text-gray-700 border-gray-200 border-e-2">
                      {row.company_name.English}
                    </td>
                    <td className="p-4 text-gray-700 border-gray-200 border-e-2">
                      {row.pan_no}
                    </td>
                    <td className="p-4 text-gray-700 border-gray-200 border-e-2">
                      {row.tax_clearance}
                    </td>
                    <td className="p-4 text-gray-700 border-gray-200 border-e-2">
                      {row.company_registration_date}
                    </td>
                    <td className="p-4 text-gray-700 border-gray-200 border-e-2">
                      {row.org_registration_date}
                    </td>
                    <td className="p-4 text-gray-700 border-gray-200 border-e-2">
                      {row.branch_name}
                    </td>
                    {isEditing? <td>
                      <Button
                        onClick={()=>{
                          setIsCreating(false)
                          setIsOpen(true)
                          setSuchiData(row)
                        }}
                      >
                        Edit
                      </Button>
                      <Button
                        onClick={()=>{
                          delSuchi(row.id)
                        }}
                      >Del</Button>

                    </td>:null}
                  </tr>
                )
              )}
            </tbody>
          </table>
        </Box>

      </Paper>
    <SuchiModal
      isOpen={isOpen}
      isCreating={isCreating}
      setIsCreating={setIsCreating}
      suchiData={suchiData}
      setTableData={setTableData}
      setIsOpen={setIsOpen}
      branchId = {branchId}
    />


    </Container>

  );
};

export default SuchiTable;