import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import { useEditing } from "../../../../context/EditingProvider";
import {
    TextField,
    Button,
    Typography,
    Container,
    Paper,
    Box,
    Grid,
    MenuItem,
    Snackbar,
    Alert,
} from "@mui/material";
import ReactModal from "react-modal";
import './ModalStyle.css';

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -55%)',
      width: '1500px',       /* Fixed width */
    height: '450px',      /* Fixed height */
    maxWidth: '90vw',    /* Prevents overflow on small screens */
    maxHeight: '90vh' ,
    },
    overlay:{
        backgroundColor: 'rgba(39, 36, 36, 0.9)'
    }
  };

const SuchiModal = ({isCreating, setIsCreating, suchiData, setTableData, isOpen, setIsOpen, branchId}) => {
    const baseUrl = useSelector((state) => state.baseUrl).backend;
    const [formData, setFormData] = useState(
        {
            company_name: { Nepali: '', English: '' },
            pan_no: '',
            tax_clearance: '',
            company_registration_date: '',
            org_registration_date: '',
            branch: branchId
        }
    );
    const [branches, setBranches] = useState([]);

    useEffect(()=>{
        if(isOpen){
            if(isCreating){
                setFormData(
                    {
                        company_name: { Nepali: '', English: '' },
                        pan_no: '',
                        tax_clearance: '',
                        company_registration_date: '',
                        org_registration_date: '',
                        branch: branchId
                    }
                );
            }else if (suchiData){
                setFormData(suchiData);
            }
        }
    },
    [isOpen, isCreating, suchiData, branchId]
    )

    useEffect(()=>{
        const fetchBranches = async () =>{
            try{
                const response = await axios.get(`${baseUrl}/api/branches/`)
                if (response.status === 200){
                    setBranches(response.data)
                }
            }catch(error){
                console.log(error)
                // show error msg
            }
        }
        fetchBranches();
    },
    [baseUrl]
    )
    const handleChange = (e) =>{
        const { name, value } = e.target;
        console.log(`${name}: ${value}`)

        if (name.startsWith('company_name.')){
            const[parent, child] = name.split('.');

            setFormData(prev => ({
                ...prev,
                [parent]:{
                    ...prev[parent],
                    [child]:value
                }
            }));
        }else{
            setFormData(prev => ({
                ...prev,
                [name]:value
            }))
        }
    };

    const handleOrgDateChange = (e) =>{
        setFormData(prev => ({
            ...prev,
            org_registration_date:e
        })

        )
    }

    const handleCompanyDateChange = (e) => {
        setFormData(prev => ({
            ...prev,
            company_registration_date:e
        }))

    }
    const handleSubmit = () =>{
        isCreating? createSuchiCall(formData) : editSuchiCall(formData)
    };

    const createSuchiCall = async (postData) => {
        try{
            const response = await axios.post(`${baseUrl}/api/suchi/`, postData)
            if (response.status === 201){
                console.log('Success')
                setTableData(prev => ([...prev, response.data]
                ))    //this needs backend filtering on response
                setIsOpen(false)
                setIsCreating(false)
                
            }
        }catch (error){
            console.log(error)
            // display error msg
        }
    } 

    const editSuchiCall = async (patchData) => {
        try{
            const response = await axios.patch(`${baseUrl}/api/suchi/${suchiData.id}/`, patchData)
            if (response.status===200){
                console.log('edit success')
                setTableData(prev => prev.map(item =>
                    item.id === suchiData.id ? {...item, ...response.data}:item
                )
            )
            setIsCreating(false)
            setIsOpen(false)
        }
        }catch(error){
            console.log(error)
            //display error msg
        }
    };

    return(
        <ReactModal
        isOpen={isOpen}
        // onRequestClose={() => setIsOpen(false)}
        contentLabel="User Form"
        style={customStyles}
        closeTimeoutMS={200}
        >
        
        <Typography variant="h5" className="modal-title text-center">
          {isCreating ? 'Create New Suchi' : 'Edit Suchi'}
        </Typography>

        <form onSubmit={handleSubmit} className="mt-8">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                name="company_name.Nepali"
                label="Company Name (Nepali)"
                value={formData.company_name.Nepali}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="company_name.English"
                label="Company Name (English)"
                value={formData.company_name.English}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="pan_no"
                label="PAN Number"
                type="number"
                value={formData.pan_no}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="tax_clearance"
                label="Tax Clearance"
                value={formData.tax_clearance}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>

            <Grid container justifyContent="center" spacing={2} sx = {{mt:2}}>
                <Grid item xs={12} sm={3}>
                    <label>Company Registration Date:</label>
                    <NepaliDatePicker
                    inputClassName="form-control"
                    className="w-fit"
                    value={formData.company_registration_date}
                    onChange={handleCompanyDateChange}
                    options={{ calenderLocale: "ne", valueLocale: "en" }}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <label>Organization Registration Date:</label>
                    <NepaliDatePicker
                    inputClassName="form-control"
                    className="w-fit"
                    value={formData.org_registration_date}
                    onChange={handleOrgDateChange}
                    options={{ calenderLocale: "ne", valueLocale: "en" }}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                    select
                    name="branch"
                    label="Branch"
                    value={formData.branch}
                    onChange={handleChange}
                    fullWidth
                    required
                    >
                    {branches.map((branch) => (
                        <MenuItem key={branch.id} value={branch.id}>
                        {branch.name}
                        </MenuItem>
                    ))}
                    </TextField>
                </Grid>
            </Grid>

        </Grid>

        <div className="flex justify-center gap-4 w-full mt-8"> 
            <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
            >
                Submit
            </Button>
            <Button
                variant="contained"
                onClick={() => setIsOpen(false)}
            >
                Close
            </Button>
        </div>

        </form>

      </ReactModal>
    )
}

export default SuchiModal