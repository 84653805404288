import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import { useEditing } from "../../../../context/EditingProvider";
import {
    TextField,
    Button,
    Typography,
    Container,
    Paper,
    Box,
    Grid,
    MenuItem,
    Snackbar,
    Alert,
    Chip,
    Divider,
} from "@mui/material";
import ReactModal from "react-modal";
import zIndex from "@mui/material/styles/zIndex";


const JatraPopup = ({ jatraObject, isModalOpen, setIsModalOpen}) => {
    console.log(jatraObject)

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (
        jatraObject && isModalOpen &&
        <div className="fixed w-full h-[110vh] bg-black/50 left-0 inset-0 flex justify-center " 
            isOpen={isModalOpen}
        >
            <Paper elevation={0} sx={{ height: '90%', width: '80%',overflow: 'auto' , p: 2, mt: 3 }}>
                    <Box>
                        <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                            <Typography variant="h4" fontWeight="bold" color="primary">
                                {jatraObject.name || "Festival Details"}
                            </Typography>
                            {/* <Chip 
                                label={jatraObject.status ? "Active" : "Inactive"} 
                                color={jatraObject.status ? "success" : "default"}
                                sx={{ fontSize: '1rem', height: 32 }}
                            /> */}
                        </Box>
                        
                        <Grid container spacing={4}>
                            <Grid item xs={12} md={4}>
                                <Box 
                                    sx={{ 
                                        border: '1px solid #e0e0e0', 
                                        borderRadius: 2, 
                                        overflow: 'hidden',
                                        height: 250,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        backgroundColor: '#f5f5f5'
                                    }}
                                >
                                    {jatraObject.image ? (
                                        <img 
                                            src={jatraObject.image} 
                                            alt={jatraObject.name} 
                                            style={{ 
                                                maxWidth: '100%', 
                                                maxHeight: '100%', 
                                                objectFit: 'contain' 
                                            }}
                                        />
                                    ) : (
                                        <Typography variant="body1" color="textSecondary">
                                            No image available
                                        </Typography>
                                    )}
                                </Box>
                                
                                {jatraObject.qr_code && (
                                    <Box 
                                        mt={2} 
                                        p={2} 
                                        border="1px solid #e0e0e0" 
                                        borderRadius={2}
                                        display="flex"
                                        flexDirection="column"
                                        alignItems="center"
                                    >
                                        <Typography variant="subtitle1" fontWeight="medium" mb={1}>
                                            QR Code
                                        </Typography>
                                        <img 
                                            src={jatraObject.qr_code} 
                                            alt="QR Code" 
                                            style={{ width: 100, height: 100 }}
                                        />
                                    </Box>
                                )}
                            </Grid>
                            
                            <Grid item xs={12} md={8}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="subtitle2" color="textSecondary">
                                            Start Date
                                        </Typography>
                                        <Typography variant="body1" fontWeight="medium">
                                            {jatraObject.start_date || "N/A"}
                                        </Typography>
                                    </Grid>
                                    
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="subtitle2" color="textSecondary">
                                            End Date
                                        </Typography>
                                        <Typography variant="body1" fontWeight="medium">
                                            {jatraObject.end_date || "N/A"}
                                        </Typography>
                                    </Grid>
                                    
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="subtitle2" color="textSecondary">
                                            Tithi From
                                        </Typography>
                                        <Typography variant="body1" fontWeight="medium">
                                            {jatraObject.tithi_from || "N/A"}
                                        </Typography>
                                    </Grid>
                                    
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="subtitle2" color="textSecondary">
                                            Tithi To
                                        </Typography>
                                        <Typography variant="body1" fontWeight="medium">
                                            {jatraObject.tithi_to || "N/A"}
                                        </Typography>
                                    </Grid>
{/*                                     
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="subtitle2" color="textSecondary">
                                            Branch
                                        </Typography>
                                        <Typography variant="body1" fontWeight="medium">
                                            {jatraObject.branch ? jatraObject.branch.name : "N/A"}
                                        </Typography>
                                    </Grid>
                                     */}
                                    <Grid item xs={12}>
                                        <Divider sx={{ my: 2 }} />
                                        <Typography variant="subtitle2" color="textSecondary" mb={1}>
                                            Description
                                        </Typography>
                                        <Typography variant="body1" paragraph sx={{ 
                                            maxHeight: '100px',
                                            overflowY: 'auto',
                                            backgroundColor: '#f9f9f9',
                                            p: 2,
                                            borderRadius: 1
                                        }}>
                                            {jatraObject.description || "No description available"}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        
                        <Box display="flex" justifyContent="flex-end" mt={3}>
                            <Button 
                                variant="outlined" 
                                color="primary" 
                                onClick={handleCloseModal}
                                sx={{ mr: 1 }}
                            >
                                Close
                            </Button>
                        </Box>
                    </Box>
            </Paper>
        </div>
    );
};

export default JatraPopup;