import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactModal from 'react-modal'; // Import ReactModal
import './index.css';
import App from './App';
import { AppProvider } from './context/AppProvider';
import 'bootstrap/dist/css/bootstrap.min.css';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { HashRouter } from 'react-router-dom';
import { store } from './state/store';
import { Provider } from 'react-redux';

// Set the app element for ReactModal (must be called before rendering)
ReactModal.setAppElement('#root');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <HashRouter>
        <I18nextProvider i18n={i18n}>
          <AppProvider>
            <App />
          </AppProvider>
        </I18nextProvider>
      </HashRouter>
    </React.StrictMode>
  </Provider>
);