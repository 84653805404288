import React, { useState } from "react";
import bgImage from "../../media/LoginSignin/rectangle.png";
import nepalLandmark from "../../media/LoginSignin/nepalLandmark.png";
import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
export const Signin = () => {
  const isMobile = useMediaQuery("(max-width:800px)");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const baseUrl = useSelector((state) => state.baseUrl).backend;
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    username: "",
    password: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName) newErrors.firstName = "First name is required";
    if (!formData.lastName) newErrors.lastName = "Last name is required";
    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.username) newErrors.username = "Username is required";
    if (!formData.password) newErrors.password = "Password is required";
    if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      const response = await axios.post(baseUrl + "api/users/", {
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        username: formData.username,
        password: formData.password,
      });
      console.log(response.data);
      navigate("/log-in");
      // Handle success (e.g., redirect to login page)
    } catch (error) {
      console.error("Error during form submission", error);
      // Handle error
    }
  };

  return (
    <>
      <div
        className="fixed top-0 w-screen h-screen -z-10"
        style={{ backgroundImage: `url(${bgImage})` }}
      ></div>
      <div className={`bg-cover bg-center`}>
        <div
          className={`${
            isMobile ? "" : "h-[80vh]"
          } flex items-center justify-center gap-3 flex-col md:flex-row lg:flex-row mx-[20px]`}
        >
          <div className={`${isMobile ? "w-[200px]" : "w-[500px]"}`}>
            <img src={nepalLandmark} alt="Nepal Landmark" />
          </div>
          <div className="flex flex-col items-center justify-center p-3">
            <div className="flex items-center justify-center text-white">
              <h1 className="mb-6 text-5xl text-yellow-400 border-b-2 border-red-500">
                Create an Account
              </h1>
            </div>
            <form
              onSubmit={handleSubmit}
              className="flex flex-col gap-3 text-white"
            >
              <div className="flex gap-3">
                <div className="flex flex-col w-full">
                  <label>
                    <h5>First Name</h5>
                  </label>
                  <input
                    type="text"
                    name="firstName"
                    className="border  rounded-md focus:outline-cyan-600 border-black p-2 w-[150px] md:w-[200px] lg:w-[300px] text-black"
                    placeholder="First Name"
                    value={formData.firstName}
                    onChange={handleChange}
                  />
                  {errors.firstName && (
                    <span className="text-red-500">{errors.firstName}</span>
                  )}
                </div>
                <div className="flex flex-col w-full lg:w-fit">
                  <label>
                    <h5>Last Name</h5>
                  </label>
                  <input
                    type="text"
                    name="lastName"
                    className="border  rounded-md focus:outline-cyan-600 border-black p-2 w-full md:w-[200px] lg:w-[300px] text-black"
                    placeholder="Last Name"
                    value={formData.lastName}
                    onChange={handleChange}
                  />
                  {errors.lastName && (
                    <span className="text-red-500">{errors.lastName}</span>
                  )}
                </div>
              </div>
              <div className="flex flex-col items-center justify-center">
                <label>
                  <h5>Email</h5>
                </label>
                <input
                  type="email"
                  name="email"
                  required
                  className="w-full p-2 text-black border border-black rounded-md focus:outline-cyan-600"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                />
                {errors.email && (
                  <span className="text-red-500">{errors.email}</span>
                )}
              </div>
              <div className="flex flex-col items-center justify-center">
                <label>
                  <h5>Username</h5>
                </label>
                <input
                  type="text"
                  name="username"
                  className="w-full p-2 text-black border border-black rounded-md focus:outline-cyan-600"
                  placeholder="Username"
                  value={formData.username}
                  onChange={handleChange}
                />
                {errors.username && (
                  <span className="text-red-500">{errors.username}</span>
                )}
              </div>
              <h5>Passwords</h5>
              <div className="flex gap-3">
                <div className="flex flex-col">
                  <label>
                    <h5></h5>
                  </label>
                  <input
                    type="password"
                    name="password"
                    className="border  rounded-md focus:outline-cyan-600 border-black p-2 w-[150px] md:w-[200px] lg:w-[300px] text-black"
                    placeholder="Password"
                    value={formData.password}
                    onChange={handleChange}
                  />
                  {errors.password && (
                    <span className="text-red-500">{errors.password}</span>
                  )}
                </div>
                <div className="flex flex-col">
                  <label>
                    <h5></h5>
                  </label>
                  <input
                    type="password"
                    name="confirmPassword"
                    className="border  rounded-md focus:outline-cyan-600 border-black p-2 w-[150px] md:w-[200px] lg:w-[300px] text-black"
                    placeholder="Confirm Password"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                  />
                  {errors.confirmPassword && (
                    <span className="text-red-500">
                      {errors.confirmPassword}
                    </span>
                  )}
                </div>
              </div>
              <Link
                to="/log-in"
                className="text-lg text-cyan-500 bg-white px-2 border-2 border-cyan-500 rounded-lg hover:text-cyan-400"
              >
                Already have an account?
              </Link>
              <div className="flex justify-center mt-1 me-2 lg:justify-end">
                <button
                  type="submit"
                  className="px-6 py-2 font-bold text-center text-white bg-blue-600 rounded-full shadow-lg hover:shadow-blue-900 hover:scale-110"
                >
                  <h5>{t("sign-up")}</h5>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
